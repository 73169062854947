import awsconfig from "../aws-exports";

export const TEMPLATE_IMAGE_URL =
  "https://t.blings.io/d109fde9-eaa9-47cc-9ea0-8c549c41091b/";

export const getEnv = () => {
  const env = awsconfig.aws_cloud_logic_custom[0].endpoint
    .split("/")
    .slice(-1)[0];
  if (env === "DEV" || env === "dev") {
    return "dev";
  }
  if (env === "staging") {
    return "staging";
  }
  return "master";
};
export const CDN = {
  dev: "https://dh2jw0564x0cd.cloudfront.net",
  staging: "https://d25az89v0z0rdg.cloudfront.net",
  master: "https://storage.blings.io",
}[getEnv()];
