import * as React from "react";

import { Select, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
// import { useHistory } from "react-router-dom";
import { MSTContext } from "../models/RootStore";
import "./ConnectToProject.scss";

type Props = {};
type IOption = {
  value: string;
  label: string;
  updatedAt: string;
  key?: string;
};

export const ConnectToProject = observer((props: Props) => {
  const {
    platformStore: {
      allProjects,
      selectedProjectId,
      isLoadingProjectList,
      selectProject,
    },
  } = useContext(MSTContext);
  //   const history = useHistory();
  const userHasMultipleAccounts = allProjects.some(
    (p) => p.projectAccountId !== allProjects[0].projectAccountId
  );
  const pjOptions: IOption[] = allProjects.map((p) => ({
    label:
      p.title + (userHasMultipleAccounts ? ` | ${p.projectAccountId}` : ""),
    value: p.id,
    updatedAt: p.updatedAt,
  }));

  const _allprojects: IOption[] = (
    JSON.parse(JSON.stringify(pjOptions)) as IOption[]
  ).sort(function (a, b) {
    // sort projects alphabeticlly
    const aName = a.label ? a.label : a.value;
    const bName = b.label ? b.label : b.value;
    if (aName.toLocaleLowerCase() < bName.toLocaleLowerCase()) {
      return -1;
    } else {
      return 1;
    }
  });
  const mostRecentProjects = (
    JSON.parse(JSON.stringify(_allprojects)) as IOption[]
  )
    .sort(function (a, b) {
      // sort projects by most recent
      const aDate = new Date(a.updatedAt);
      const bDate = new Date(b.updatedAt);
      if (aDate < bDate) {
        return 1;
      } else {
        return -1;
      }
    })
    .filter((p, i) => {
      if (i < 5) {
        p.key = p.label + p.updatedAt + i.toString();
        return true;
      }
    });
  return (
    <>
      <Space
        className="ConnectToProject"
        style={{
          marginLeft: "1rem",
          //   ...(selectedProjectId === null ? { display: "none" } : undefined),
        }}
      >
        <Select<string>
          id="project-list-selector"
          placeholder={
            isLoadingProjectList ? "Loading projects" : "Select a project"
          }
          value={selectedProjectId || undefined}
          options={[
            {
              label: "Recent projects",
              value: "recent",
              options: mostRecentProjects,
            },
            {
              label: "All projects",
              value: "all",
              options: _allprojects,
            },
          ]}
          onChange={(v) => {
            // history.push(`/${v}`);
            selectProject(v);
          }}
          showSearch
          loading={isLoadingProjectList}
          style={{ minWidth: "13rem" }}
          filterOption={(input, option) => {
            return (
              (option as IOption)?.label
                .toLowerCase()
                .indexOf(input.toLowerCase()) > -1 ||
              (option as IOption)?.value
                .toLowerCase()
                .indexOf(input.toLowerCase()) > -1
            );
          }}
          disabled={isLoadingProjectList}
        />
        <br />
      </Space>
    </>
  );
});
