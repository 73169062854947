import { Animation, LayerType } from "@blings/blings-player";
import { uploadData } from "aws-amplify/storage";
import { CDN, TEMPLATE_IMAGE_URL, getEnv } from "./getEnv";
import { FileAsset } from "@blings/blings-player/lib/src/libs/l/types/assets";
import { uploadImageToS3 } from "./uploadImageToS3";

function getKeyAndImageName(asset: FileAsset, projectId: string) {
  const assetId = asset.id.replace("--image_", "");
  const key = `projects/${projectId}/assets/images/${assetId}.png`;
  const imageTextValue = asset.u?.replace(TEMPLATE_IMAGE_URL, "");
  return { key, imageTextValue };
}

export const uploadAnimations = async (
  projectId: string,
  animations: Array<Animation>
) => {
  const now = Date.now();
  const uploadPromises = animations.map(async (animation) => {
    const path = `projects/${projectId}/assets/${now}_${animation.nm}.json`;
    const url = `${CDN}/${path}`;
    // copy animation object - we can't modify the original object
    const animationData = JSON.parse(JSON.stringify(animation)) as Animation;

    for (const layer of animationData.layers) {
      // check if layer is image
      if (layer.ty === LayerType.still) {
        const asset = animationData.assets?.find(
          (asset) => asset.id === layer.refId
        ) as FileAsset;
        if (asset) {
          try {
            const { key, imageTextValue } = getKeyAndImageName(
              asset,
              projectId
            );
            const result = await uploadImageToS3(key, imageTextValue);
            if (!result.key) {
              throw new Error("Faild to upload Image");
            }
            asset.p = `${CDN}/${result.key}`;
            asset.u = "";
          } catch (e) {
            if (getEnv() === "dev") {
              console.error("uploadImageToS3 ERROR", e);
            }
          }
        }
      }
    }

    const uploadOptions = {
      path: `public/${path}`,
      data: JSON.stringify(animationData),
      options: {
        accessLevel: "guest",
        contentType: "application/json",
      },
    };
    await uploadData(uploadOptions).result;
    return url;
  });
  return Promise.all(uploadPromises);
};
