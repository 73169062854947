export const FileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7379 2.76175L8.08493 2.76175C6.00493 2.75375 4.29993 4.41175 4.25093 6.49075L4.25093 17.2037C4.20493 19.3167 5.87993 21.0677 7.99293 21.1147C8.02393 21.1147 8.05393 21.1157 8.08493 21.1147H16.0739C18.1679 21.0297 19.8179 19.2997 19.8029 17.2037V8.03775L14.7379 2.76175Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4746 2.75V5.659C14.4746 7.079 15.6226 8.23 17.0426 8.234H19.7976"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2887 15.3584H8.88867"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2427 11.6064H8.88672"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const AddFileIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7366 2.76175L8.08455 2.76175C6.00455 2.75375 4.29955 4.41075 4.25055 6.49075L4.25055 17.3397C4.21555 19.3897 5.84855 21.0807 7.89955 21.1167C7.96055 21.1167 8.02255 21.1167 8.08455 21.1147H16.0726C18.1416 21.0937 19.8056 19.4087 19.8026 17.3397V8.03975L14.7366 2.76175Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4746 2.75V5.659C14.4746 7.079 15.6236 8.23 17.0436 8.234H19.7976"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2945 12.9141H9.39453"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8438 15.3639V10.4639"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ImageIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.21 7.899V16.05C21.21 19.07 19.32 21.2 16.3 21.2H7.65C4.63 21.2 2.75 19.07 2.75 16.05L2.75 7.899C2.75 4.879 4.64 2.75 7.65 2.75L16.3 2.75C19.32 2.75 21.21 4.879 21.21 7.899Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.28125 16.4303L6.80925 14.8173C7.34025 14.2543 8.22525 14.2273 8.78925 14.7573C8.80625 14.7743 9.72625 15.7093 9.72625 15.7093C10.2813 16.2743 11.1883 16.2833 11.7533 15.7293C11.7903 15.6933 14.0872 12.9073 14.0872 12.9073C14.6792 12.1883 15.7422 12.0853 16.4622 12.6783C16.5102 12.7183 18.6803 14.9453 18.6803 14.9453"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3127 9.13291C10.3127 10.1019 9.52769 10.8869 8.55869 10.8869C7.58969 10.8869 6.80469 10.1019 6.80469 9.13291C6.80469 8.16391 7.58969 7.37891 8.55869 7.37891C9.52769 7.37991 10.3127 8.16391 10.3127 9.13291Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const UpDownArrowsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8407 20.1637V6.5459"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9173 16.0674L16.8395 20.164L12.7617 16.0674"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.91102 3.83301L6.91102 17.4508"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.83398 7.9287L6.91176 3.83203L10.9895 7.9287"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const VideoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2976 15.5383C16.3785 17.3704 14.8999 18.9196 12.9953 18.9975C12.8551 19.0034 6.01604 18.9896 6.01604 18.9896C4.12069 19.1335 2.46188 17.7715 2.31238 15.9463C2.30112 15.8103 2.30419 8.47219 2.30419 8.47219C2.22023 6.63815 3.69678 5.08499 5.60236 5.00418C5.74469 4.99728 12.5745 5.01009 12.5745 5.01009C14.4791 4.86818 16.143 6.24001 16.2904 8.07405C16.3007 8.2061 16.2976 15.5383 16.2976 15.5383Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3008 9.97984L19.5938 7.28484C20.4098 6.61684 21.6338 7.19884 21.6328 8.25184L21.6208 15.6008C21.6198 16.6538 20.3948 17.2308 19.5808 16.5628L16.3008 13.8678"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TicketIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8496 4.25V6.67"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8496 17.7598V19.7838"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8496 14.3239V9.50293"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7021 20C20.5242 20 22 18.5426 22 16.7431V14.1506C20.7943 14.1506 19.8233 13.1917 19.8233 12.001C19.8233 10.8104 20.7943 9.85039 22 9.85039L21.999 7.25686C21.999 5.45745 20.5221 4 18.7011 4L5.29892 4C3.47789 4 2.00104 5.45745 2.00104 7.25686L2 9.93485C3.20567 9.93485 4.17668 10.8104 4.17668 12.001C4.17668 13.1917 3.20567 14.1506 2 14.1506L2 16.7431C2 18.5426 3.4758 20 5.29787 20H18.7021Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SendIcon = (params: any = "#130F26") => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="3 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3806 16.8655L14.2457 17.0697L12.374 9.62332C12.106 8.55629 13.2276 7.66935 14.202 8.17798L28.4813 15.6251C29.374 16.0904 29.3935 17.362 28.515 17.8619L14.5156 25.8283C13.5577 26.3729 12.4113 25.5328 12.6437 24.4569L14.2428 17.0684"
        stroke={params.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TitleIcon = (params: any = "#130F26") => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 15H11.25"
        stroke="#130F26"
        strokeWidth="1.50545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5.25V3H15V5.25"
        stroke="#130F26"
        strokeWidth="1.50545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3V15"
        stroke="#130F26"
        strokeWidth="1.50545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const EditIcon = (params: any = "#130F26") => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.748 20.4428H21.0006"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0215 6.00098L16.4737 10.1881"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ControllerIcon = (params: any = "#130F26") => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.84819 12.3145V16.0594"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7586 14.1863H6.9375"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3665 12.4285H15.2595"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.179 16.0027H17.072"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.07227 2C8.07227 2.74048 8.68475 3.34076 9.44029 3.34076H10.4968C11.6624 3.34492 12.6065 4.27026 12.6118 5.41266V6.08771"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4283 21.9623C13.4231 22.0132 10.473 22.0111 7.57275 21.9623C4.3535 21.9623 2 19.666 2 16.5109V11.8614C2 8.70627 4.3535 6.41005 7.57275 6.41005C10.4889 6.3602 13.4411 6.36123 16.4283 6.41005C19.6476 6.41005 22 8.70731 22 11.8614V16.5109C22 19.666 19.6476 21.9623 16.4283 21.9623Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const DBIcon = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.666 13.8086C24.666 15.7188 20.0632 17.2607 14.3096 17.2607C8.55599 17.2607 3.95312 15.7188 3.95312 13.8086"
        stroke="#8C8C8C"
        strokeWidth="2.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3096 9.20605C20.0293 9.20605 24.666 7.66048 24.666 5.75391C24.666 3.84734 20.0293 2.30176 14.3096 2.30176C8.58986 2.30176 3.95312 3.84734 3.95312 5.75391C3.95312 7.66048 8.58986 9.20605 14.3096 9.20605Z"
        stroke="#8C8C8C"
        strokeWidth="2.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.95312 5.75391V21.8639C3.95312 23.7741 8.55599 25.3161 14.3096 25.3161C20.0632 25.3161 24.666 23.7741 24.666 21.8639V5.75391"
        stroke="#8C8C8C"
        strokeWidth="2.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
