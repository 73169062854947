import config from "../aws-exports";
// Version 4 hardcoded
const latestVersionLink = {
  dev: "https://assets.blings.io/scripts/msdkDev4.js",
  master: "https://assets.blings.io/scripts/msdk4.js",
};
export async function getLatestPlayerVersion() {
  const env = config.aws_cloud_logic_custom[0].endpoint.split("/").slice(-1)[0];
  //@ts-ignore
  const sdkScript = await fetch(latestVersionLink?.[env] || "dev", {
    headers: { Accept: "application/javascript" },
  });
  const sdkScriptText = await sdkScript.text();
  // Regex to get the version number between blingsSDK_v and .js
  const versionRegex = /\|\|\\?"\s*(\d+-\d+-\d+)/;
  const version = versionRegex.exec(sdkScriptText)?.[1];
  return version;
}
