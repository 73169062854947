// models/RootStore.ts
import { types, Instance } from "mobx-state-tree";
import { ScriptStore } from "./ScriptStore";
import { ProjectStore } from "./ProjectsStore";
import { AccountStore } from "./AccountStore";
import { createContext } from "react";
import { PlatformModel } from "./PlatformStore";

export const RootStore = types.model("RootStore", {
  scriptStore: types.optional(ScriptStore, {}),
  projectStore: types.optional(ProjectStore, {}),
  accountStore: types.optional(AccountStore, {}),
  platformStore: types.optional(PlatformModel, {}),
});
export const rootStore = RootStore.create({});

export interface IRootStore extends Instance<typeof RootStore> {}
export const MSTContext = createContext(rootStore);
