import { BlingVideo, Scene, ContentItem } from "./scriptToBlings";
import { createBackgroundLayer } from "./backgroundLayer";
import { LayerType, Animation } from "@blings/blings-player";
import {
  AudioLayer,
  CameraLayer,
  DataLayer,
  ImageLayer,
  PrecompositionLayer,
  ShapeLayer,
  SolidColorLayer,
  TextLayer,
} from "@blings/blings-player/lib/src/libs/l/types/layers";
import { TEMPLATE_IMAGE_URL } from "./getEnv";
import {
  DataSource,
  FileAsset,
  Image,
  Precomposition,
} from "@blings/blings-player/lib/src/libs/l/types/assets";

const BASE_VIDEO_WIDTH = 1080;
const BASE_VIDEO_HEIGHT = 1920;
const BASE_FRAME_RATE = 25;
const BASE_IMAGE_SIZE = 450;

const createBaseAnimationData = (
  sceneName: string,
  screens: Scene["screens"]
): Animation => ({
  fr: BASE_FRAME_RATE,
  ip: 0,
  op: 0,
  w: BASE_VIDEO_WIDTH,
  h: BASE_VIDEO_HEIGHT,
  nm: sceneName,
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: "",
        fClass: "",
        fFamily: "_Nunito",
        fWeight: "400",
        fStyle: "normal",
        fName: "Nunito-Regular",
        // fStyleAndWeight: "Regular",
        ascent: 71.4991455432028,
        // loaded: true,
      },
      {
        origin: 0,
        fPath: "",
        fClass: "",
        fFamily: "_Nunito",
        fWeight: "700",
        fStyle: "normal",
        fName: "Nunito-Bold",
        // fStyleAndWeight: "Bold",
        ascent: 71.5991455130279,
        // loaded: true,
      },
    ],
  },
  layers: [],
  markers: [],
  meta_b: {
    fffUrl: [],
    fffDef: [
      {
        url: "https://d1t5lnx6iwscgl.cloudfront.net/projects/b4a0ea02-3caf-48d6-810d-3fa8412d3aa9/assets/fonts/02763f8e_Nunito-Regular.woff2",
        family: "_Nunito",
        style: "normal",
        name: "Nunito-Regular",
        // file: {},
        isVariableFont: false,
        weight: "400",
      },
      {
        url: "https://d1t5lnx6iwscgl.cloudfront.net/projects/b4a0ea02-3caf-48d6-810d-3fa8412d3aa9/assets/fonts/7d87af0f_Nunito-Bold.woff2",
        family: "_Nunito",
        style: "normal",
        name: "Nunito-Bold",
        // file: {},
        isVariableFont: false,
        weight: "700",
      },
    ],
  },
  scenes: [],
  scenesNewestExtensionVersion: "7.4.2",
  scenesOldestExtensionVersion: "7.4.2",
  __complete: true,
});

const opacityTransition = (t: number) => [
  {
    i: { x: [0.667], y: [1] },
    o: { x: [0.167], y: [0.167] },
    t: t,
    s: [0],
  },
  {
    t: t + 50,
    s: [100],
  },
];

const createTextLayer = (
  content: ContentItem,
  startFrame: number,
  verticalPosition: number,
  durationOfScreenInSeconds: number
): TextLayer => {
  const isButton = content.type === "button";
  return {
    ddd: 0,
    sr: 1,
    ao: 0,
    ip: startFrame,
    op: startFrame + durationOfScreenInSeconds * BASE_FRAME_RATE,
    st: startFrame,
    bm: 0,
    uid: content.uid,
    ty: LayerType.text,
    nm: content.value,
    ks: {
      o: { a: 1, k: opacityTransition(startFrame), ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [200, verticalPosition, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    t: {
      d: {
        k: [
          {
            s: {
              s: isButton ? 60 : content.type === "title" ? 75 : 50,
              f: isButton
                ? "Nunito-Bold"
                : content.type === "title"
                ? "Nunito-Bold"
                : "Nunito-Regular",
              sz: [1000, 200],
              ps: [-167.5, -35.5],
              t: content.value,
              ca: 0,
              dir: 0,
              j: 2,
              tr: 0,
              lh: isButton ? 72 : content.type === "title" ? 106.8 : 72,
              ls: 0,
              fc: isButton ? [0, 0.224, 0.973] : [0, 0, 0],
            },
            t: 0,
          },
        ],
      },
      p: {},
      m: {
        g: 1,
        a: { a: 0, k: [0, 0], ix: 2 },
      },
      a: [],
    },
    cl: " _ctr",
  };
};

const createImageLayer = (
  content: ContentItem,
  startFrame: number,
  verticalPosition: number,
  durationOfScreenInSeconds: number,
  elementId: string
): ImageLayer => {
  return {
    ddd: 0,
    sr: 1,
    ao: 0,
    ip: startFrame,
    op: startFrame + durationOfScreenInSeconds * BASE_FRAME_RATE,
    st: startFrame,
    bm: 0,
    uid: content.uid,
    ty: LayerType.still,
    cl: "png",
    nm: content.value,
    refId: elementId,
    cp: false,
    ind: 1,
    ks: {
      //@ts-ignore
      o: { a: 0, k: opacityTransition(startFrame), ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 0,
        k: [
          BASE_VIDEO_WIDTH / 2 - BASE_IMAGE_SIZE / 2,
          verticalPosition - BASE_IMAGE_SIZE / 2 + 150,
          0,
        ],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
  };
};
const createButton = (
  content: ContentItem,
  startFrame: number,
  verticalPosition: number,
  durationOfScreenInSeconds: number,
  elementId: string
): {
  assets: Array<Image | FileAsset | Precomposition | DataSource>;
  layers: Array<
    | TextLayer
    | ImageLayer
    | PrecompositionLayer
    | SolidColorLayer
    | ShapeLayer
    | AudioLayer
    | CameraLayer
    | DataLayer
  >;
} => {
  const { uid, value } = content;
  const maxChars = 13;
  const originalFontSize = 60;
  const fontSize = originalFontSize / Math.max(1, value.length / maxChars);
  return {
    assets: [
      {
        // uid: uid * 10000 + 1,
        pr: "xMidYMid meet",
        id: elementId,
        nm: `Button ${value}`,
        fr: 25,
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 5,
            nm: `Button ${value} text`,
            uid: uid * 10000 + 2,
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [374.25, 99.33, 0],
                ix: 2,
                l: 2,
              },
              a: {
                a: 0,
                k: [0, -11, 0],
                ix: 1,
                l: 2,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
                l: 2,
              },
            },
            ao: 0,
            t: {
              d: {
                k: [
                  {
                    s: {
                      s: fontSize,
                      f: "Nunito-Bold",
                      t: value,
                      ca: 0,
                      dir: 0,
                      j: 2,
                      tr: 0,
                      lh: 43.2000007629395,
                      ls: 0,
                      fc: [0.922, 0.922, 0.922],
                    },
                    t: 0,
                  },
                ],
              },
              p: {},
              m: {
                g: 1,
                a: {
                  a: 0,
                  k: [0, 0],
                  ix: 2,
                },
              },
              a: [],
            },
            ip: 0,
            op: 750.750750750751,
            st: 0,
            ct: 1,
            cp: true,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: `Button ${value} background`,
            uid: uid * 10000 + 3,
            sr: 1,
            ks: {
              o: {
                a: 0,
                k: 100,
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
              },
              p: {
                a: 0,
                k: [353.25, 90.3, 0],
                ix: 2,
                l: 2,
              },
              a: {
                a: 0,
                k: [0, 0, 0],
                ix: 1,
                l: 2,
              },
              s: {
                a: 0,
                k: [100, 100, 100],
                ix: 6,
                l: 2,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: "gr",
                it: [
                  {
                    ty: "rc",
                    d: 1,
                    s: {
                      a: 0,
                      k: [700, 168],
                      ix: 2,
                    },
                    p: {
                      a: 0,
                      k: [0, 0],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 20,
                      ix: 4,
                    },
                    nm: "Rectangle Path 1",
                    mn: "ADBE Vector Shape - Rect",
                    hd: false,
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [0, 0, 0, 1],
                      ix: 4,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 5,
                    },
                    r: 1,
                    bm: 0,
                    nm: "Fill 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false,
                  },
                  {
                    ty: "tr",
                    p: {
                      a: 0,
                      k: [7.729, -1.082],
                      ix: 2,
                    },
                    a: {
                      a: 0,
                      k: [0, 0],
                      ix: 1,
                    },
                    s: {
                      a: 0,
                      k: [100, 100],
                      ix: 3,
                    },
                    r: {
                      a: 0,
                      k: 0,
                      ix: 6,
                    },
                    o: {
                      a: 0,
                      k: 100,
                      ix: 7,
                    },
                    sk: {
                      a: 0,
                      k: 0,
                      ix: 4,
                    },
                    sa: {
                      a: 0,
                      k: 0,
                      ix: 5,
                    },
                    nm: "Transform",
                  },
                ],
                nm: "Rectangle 1",
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: "ADBE Vector Group",
                hd: false,
              },
            ],
            ip: 0,
            op: 750.750750750751,
            st: 0,
            ct: 1,
            cp: true,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: LayerType.precomp,
        nm: `Button ${value}`,
        uid,
        refId: elementId,
        sr: 1,
        ks: {
          o: { a: 1, k: opacityTransition(startFrame), ix: 11 },
          r: {
            a: 0,
            k: 0,
            ix: 10,
          },
          p: {
            a: 0,
            k: [BASE_VIDEO_WIDTH / 2, verticalPosition, 0],
            ix: 2,
            l: 2,
          },
          a: {
            a: 0,
            k: [125*3, 40*3, 0],
            ix: 1,
            l: 2,
          },
          s: {
            a: 0,
            k: [100, 100, 100],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        w: 750,
        h: 240,
        ip: startFrame,
        op: startFrame + durationOfScreenInSeconds * BASE_FRAME_RATE,
        st: startFrame,
        cp: false,
        bm: 0,
      },
    ],
  };
};
const createLayer = (
  content: ContentItem,
  startFrame: number,
  verticalPosition: number,
  durationOfScreenInSeconds: number,
  elementId: string
): {
  assets: Array<Image | FileAsset | Precomposition | DataSource>;
  layers: Array<
    | TextLayer
    | ImageLayer
    | PrecompositionLayer
    | SolidColorLayer
    | ShapeLayer
    | AudioLayer
    | CameraLayer
    | DataLayer
  >;
} => {
  switch (content.type) {
    case "button":
      return createButton(
        content,
        startFrame,
        verticalPosition,
        durationOfScreenInSeconds,
        elementId
      );
    case "text":
    case "title":
      return {
        assets: [],
        layers: [
          createTextLayer(
            content,
            startFrame,
            verticalPosition,
            durationOfScreenInSeconds
          ),
        ],
      };
    case "image":
      return {
        assets: [
          {
            id: elementId,
            w: BASE_IMAGE_SIZE,
            h: BASE_IMAGE_SIZE,
            p: "",
            u: TEMPLATE_IMAGE_URL + content.value,
            e: 0,
            pr: "xMidYMid meet",
          },
        ],
        layers: [
          createImageLayer(
            content,
            startFrame,
            verticalPosition,
            durationOfScreenInSeconds,
            elementId
          ),
        ],
      };
    default:
      throw new Error(`Unsupported content type: ${content.type}`);
  }
};

export const convertToMP5 = (
  blingVideo: BlingVideo
): { animationData: Animation[]; connectors: Scene["connectors"][] } => {
  const animationData = blingVideo.scenes.map((scene) =>
    convertSceneToMP5(scene)
  );

  const connectors = blingVideo.scenes.map((scene) => scene.connectors);
  return { animationData, connectors };
};

const getSeconds = (countTextChars: number) => {
  return countTextChars > 200
    ? 6
    : countTextChars > 140
    ? 5
    : countTextChars > 80
    ? 4
    : 2.5;
};

const parseImageName = (assetName: string) => {
  return assetName.replace(/[^a-zA-Z0-9]/g, "");
};

const getElementId = (content: ContentItem) => {
  return `${new Date().getTime()}${content.uid}_${parseImageName(
    content.value
  )}--${content.type}_`;
};

const convertSceneToMP5 = (scene: Scene): Animation => {
  const animationData = createBaseAnimationData(scene.name, scene.screens);
  let startFrame = 0;
  let totalAnimationDuration = 0;

  scene.screens.forEach((screen, screenIndex) => {
    const totalContent = screen.content.length;
    const basePosition = (BASE_VIDEO_HEIGHT - totalContent * 210) / 2;
    const countTextChars = screen.content
      .filter((content) => content.type === "text")
      .reduce((acc, content) => acc + content.value.length, 0);
    const durationOfScreenInSeconds = getSeconds(countTextChars);

    totalAnimationDuration += durationOfScreenInSeconds;
    let extraSpace = 0;
      
    screen.content.forEach((content, contentIndex) => {
      const topPosition = basePosition + contentIndex * 210;
      const elementId = getElementId(content);

      if(content.type === 'button'){
        extraSpace += 75;
      }

      const { assets, layers } = createLayer(
        content,
        startFrame,
        topPosition + extraSpace,
        durationOfScreenInSeconds,
        elementId,
        
      );

      if(content.type === 'image'){
        extraSpace += 300;
      }


      animationData.layers.push(...layers);
      animationData.assets?.push(...assets);
    });
    startFrame += durationOfScreenInSeconds * BASE_FRAME_RATE;
  });
  animationData.op = totalAnimationDuration * BASE_FRAME_RATE;

  animationData.layers.push(
    createBackgroundLayer(
      totalAnimationDuration * BASE_FRAME_RATE,
      BASE_VIDEO_WIDTH,
      BASE_VIDEO_HEIGHT
    )
  );

  return animationData;
};
