import DynamicDataForm from "./DynamicDataForm";
import { useStore } from "../hooks/useStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { DBIcon } from "../icons-and-animations/icons";
const DataPropertiesDiv = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  height: 100%;
  max-width: 300px;
`;

const DataPropertiesSection: React.FC = observer(() => {
  const { scriptStore } = useStore();

  return (
    <DataPropertiesDiv>
      <span
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "#45464E",
          paddingLeft: "20px",
        }}
      >
        MP5 Dynamic Data
      </span>

      {scriptStore.dynamicData.size > 0 ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            maxHeight: "75vh",
            width: "100%",
          }}
        >
          <DynamicDataForm />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            padding: "10px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <DBIcon />
          <p style={{ color: "#BEC0CA" }}>
            Describe your use case to create a data property of your demo video
          </p>
        </div>
      )}
    </DataPropertiesDiv>
  );
});
export default DataPropertiesSection;
