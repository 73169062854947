import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/useStore";
import PlayerSection from "./PlayerSection";
import DataPropertiesSection from "./DataPropertiesSection";
import { ScriptSection } from "./ScriptSection";
import { useState } from "react";
import CreateProjectModal from "./CreateProjectModal";
import Header from "./Header";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 20px;
  width: 100%;
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1.6fr 1fr;
  gap: 20px;
  height: 80vh;
  justify-items: end;
`;

const ScriptConverter = observer(() => {
  const { projectStore } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async (projectName: string, account: string) => {
    try {
      projectStore.setName(projectName);
      projectStore.setAccount(account);
      await projectStore.createProject();
    } catch (error) {
      console.log("error", error);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Header showModal={showModal}></Header>
      <MainContent>
        <ScriptSection></ScriptSection>
        <PlayerSection></PlayerSection>
        <DataPropertiesSection></DataPropertiesSection>
      </MainContent>
      {isModalOpen && (
        <CreateProjectModal
          isOpen={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      )}
    </Container>
  );
});

export default ScriptConverter;
