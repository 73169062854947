type InputSchema = {
    [key: string]: {
      type: string;
      examples: string[];
      format: string;
    };
  };
  
  interface OutputProperty {
    type: string;
    examples: string[];
    format: string;
  }
  
  interface OutputSchema {
    $schema: string;
    type: string;
    properties: { [key: string]: OutputProperty };
    examples: Array<{ [key: string]: string }>;
  }
  
export  function convertSchema(input: InputSchema): OutputSchema {
    const output: OutputSchema = {
      $schema: "http://json-schema.org/draft-07/schema",
      type: "object",
      properties: {},
      examples: [{}]
    };
  
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const property = input[key];
        output.properties[key] = {
          type: property.type,
          examples: property.examples,
          format: property.format
        };
        output.examples[0][key] = property.examples[0];
      }
    }
  
    return output;
  }
  
  
  