import BlingsVideoPlayer from "./BlingsVideoPlayer";
import { FiVideoOff } from "react-icons/fi";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/useStore";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
`;

const VideoPlayerWrapper = styled.div`
  border-radius: 30px;
  background-color: #f9f9f9;
  border: 7px solid #dadfe5;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const NoVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: auto 9 / 16;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const FrameIndecator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 12px;
  color: #6e7079;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #45464e;
`;

const SectionTitle = styled.span`
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-start;
  width: 100%;
`;

const PlayerSection: React.FC = observer(() => {
  const { scriptStore } = useStore();
  return (
    <Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SectionTitle>
          <Title>MP5 Demo</Title>
        </SectionTitle>
        <div
          style={{
            width: "100%",
            maxWidth: "calc(70vh * 9 / 16)",
            height: "100%",
            position: "relative",
            aspectRatio: "auto 9/16",
            maxHeight: "75vh",
          }}
        >
          <VideoPlayerWrapper
            style={{
              borderColor: scriptStore.canConvertToBlings ? "black" : "#dadfe5",
            }}
          >
            {scriptStore.canConvertToBlings ? (
              <BlingsVideoPlayer />
            ) : (
              <NoVideoWrapper>
                <FiVideoOff style={{ fontSize: "30px", color: "#8C8C8C" }} />
                <p style={{ color: "#BEC0CA", padding: "0 10px" }}>
                  Describe your use case to create a demo of your video
                </p>
              </NoVideoWrapper>
            )}
          </VideoPlayerWrapper>
        </div>
      </div>
      {scriptStore.canConvertToBlings ? (
        <FrameIndecator>
          {scriptStore.currentFrame} / {scriptStore.totalFrame}
        </FrameIndecator>
      ) : null}
    </Container>
  );
});

export default PlayerSection;
