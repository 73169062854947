export enum ScriptComponent {
    SCENE = "scene",
    SCREEN = "screen",
    BUTTON = "button",
    IMAGE = "image",
    TITLE = "title",
    DYNAMIC_VALUE = "dynamicValue",
    VIDEO_TEXT = "text",
}

export function identifyScriptComponent(line: string): ScriptComponent {
    if (isScene(line)) {
        return ScriptComponent.SCENE;
    } else if (isScreen(line)) {
        return ScriptComponent.SCREEN;
    } else if (isButton(line)) {
        return ScriptComponent.BUTTON;
    } else if (isImage(line)) {
        return ScriptComponent.IMAGE;
    } else if (isTitle(line)) {
        return ScriptComponent.TITLE;
    } else {
        return ScriptComponent.VIDEO_TEXT;
    }
}

/**
 * Check if a line is a scene by checking if the first 3 and last 3 characters are "=" and there's other characters in between
 * @param line The line to check if it is a scene
 */
function isScene(line: string): boolean {
    const sceneRegex = /^={3}[^=]+={3}$/;
    return sceneRegex.test(line);
}

/**
 * Check if a line is a screen by checking if the first and last characters are "*" and there's no other characters in between
 * @param line The line to check if it is a screen
 */
function isScreen(line: string): boolean {
    const screenRegex = /^\*{2}$/;
    return screenRegex.test(line);
}

/**
 * Check if a line is a button by checking if the first 2 and last 2 characters are "[" and "]" and if there's the button data in between
 * @param line The line to check if it is a button
 * @example [[BUTTON: Click here to contact us => company.com/contact-us]]
 */
function isButton(line: string): boolean {
    const buttonRegex = /^\[\[BUTTON: .+ => .+\]\]$/;
    return buttonRegex.test(line);
}

/**
 * Check if a line is an image by checking if the first 2 and last 2 characters are "[" and "]" and if there's the image data in between
 * @param line The line to check if it is an image
 * @example [[IMAGE: menu_item_1_image]]
 */
function isImage(line: string): boolean {
    const imageRegex = /^\[\[IMAGE: .+\]\]$/;
    return imageRegex.test(line);
}

/**
 * Check if a line is a title by checking if the first and last characters, other than space, are "*" and there's other characters in between
 * @param line The line to check if it is a title
 */
function isTitle(line: string): boolean {
    const titleRegex = /^\*[^*]+\*$/
    return titleRegex.test(line);
}