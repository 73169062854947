import React, { useEffect, useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { useStore } from "../hooks/useStore";
import { Animation, BlingsPlayer } from "@blings/blings-player";
import { debounce } from "lodash";

const PlayerContainer = styled.div`
  width: 100%;
  background-color: #000000;
  border-radius: 8px;
  position: relative;
  max-height: 90vh;
  min-height: 50vh;
  width: 100%;
  padding-top: 20px;
`;

const BlingsVideoPlayer: React.FC = observer(() => {
  const { scriptStore } = useStore();
  const playerRef = useRef<HTMLDivElement>(null);

  const destroyAndCreate = useCallback(
    debounce(async () => {
      if (scriptStore.mp5Data.length > 0 && playerRef.current) {
        const videoParts = scriptStore.mp5Data
          .map((scene: Animation, index: number) => ({
            name: scene.nm || "",
            json: scene as any,
            modsArr: scriptStore.connectors[index] as any, // Ensure connectors are of the correct type
          }))
          .filter((videoPart) => videoPart.name);
        if ((window as any).p) {
          try {
            await (window as any).p.destroy();
          } catch (e) {
            console.error(e);
          }
        }

        const dynamicData: Record<string, string> = {};
        scriptStore.dynamicData.forEach((field, key) => {
          dynamicData[key as string] = field.examples[0] as string;
        });

        const player = await BlingsPlayer.create({
          data: dynamicData,
          project: {
            liveControlData: {},
            videoParts: videoParts,
          },
          settings: {
            maxLoadingTime: 100,
            container: playerRef.current,
            cinematic: false,
          },
          scenes: videoParts.map((part) => part.name),
        });
        (window as any).p = player;
        player.EE.on("onFrame", (frame: number) => {
          scriptStore.setCurrentFrame(frame, player.getTotalFrames());
        });
        player.EE.on("onAllReady", () => {
          scriptStore.setCurrentFrame(0, player.getTotalFrames());
        });
      }
    }, 300),
    []
  );

  useEffect(() => {
    const initializePlayer = async () => {
      await destroyAndCreate();
    };

    initializePlayer();
  }, [scriptStore.mp5Data.toJSON(), scriptStore.dynamicData.toJSON()]);

  return <PlayerContainer ref={playerRef} id="vid-holder" />;
});

export default BlingsVideoPlayer;
