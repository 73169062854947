import axios from "axios";

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_API_URL = "https://api.openai.com/v1/chat/completions";

export async function callOpenAI(prompt: string): Promise<string> {
  try {
    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: "You are a helpful assistant that converts video descriptions into formatted scripts."
          },
          {
            role: "user",
            content: `
Convert the following video description into a formatted script:

${prompt}

### Instructions for Creating a Video Script
1. Identify Scenes: Break down the video description into logical scenes. Each scene should cover a distinct part of the video content.
2. Label Scenes: Assign a clear label to each scene. Use the format:
   === SCENE: Scene Name ===
3. Script Content: Write the script content for each scene. Ensure to:
   - Introduce each part with a greeting or key message.
   - Provide the main content of the scene.
   - Include any call-to-action phrases or important instructions.
4. Screen Splits: Indicate new screens within a scene using double asterisks (**). Place them between lines that are intended to appear on separate screens.
5. Images: Mark image placeholders with double brackets and a brief description of the image. For example:
   [[ IMAGE: company logo ]]
6. Buttons: Highlight clickable buttons using double brackets. For example:
   [[ BUTTON: Click here to contact us => company.com/contact-us ]]
7. When using dyanmic data, embed dynamic variables directly within the text using curly braces. For example: "Hello {name}, we have an important update regarding your bonus."

### Example Scripts

Example 1:
=== SCENE: Welcome ===
Hello {name}
**
We're thrilled to see you again.

=== SCENE: Points Earned ===
You have earned {loyalty_points} points this month!
Keep enjoying your favorite meals to earn more points.

=== SCENE: Special Offer ===
*Special Offer Just for You*
**
As a valued member, get a {discount_percentage}% discount on your next order.
Use code 
{discount_code}
at checkout.

=== SCENE: New Menu ===
*Check Out Our New Menu*
We've added delicious new items just for you!
**
Try our new 
{menu_item_1} 
[[ IMAGE: menu_item_1_image ]]
McWhopper
[[ IMAGE: mcwhopper_image ]]

=== SCENE: Rewards and Offers ===
Don't miss out on these exclusive rewards and offers.
**
Order Now to enjoy your favorites and earn more points! 
[[ BUTTON: Order now ]]

=== SCENE: Appreciation ===
Thank you {name} for being a loyal customer!
We appreciate your support.
**
Visit Our Website for more details.
[[ BUTTON: Mcd Website => mcd.com ]]

Example 2:
=== SCENE: Introduction ===
Welcome {name}!
**
Thank you for choosing our service.

=== SCENE: Account Summary ===
Here is your account summary for the month:
- Balance: {account_balance}
- Transactions: {transaction_count}

=== SCENE: Tips and Tricks ===
*Tips for Better Management*
**
Keep your account secure by enabling two-factor authentication.
**
Review your transactions regularly to monitor for any suspicious activity.

=== SCENE: Contact Us ===
Need help? Our support team is here for you.
[[ BUTTON: Contact Support => company.com/support ]]
[[ IMAGE: support_team_photo ]]

Example 3:
=== SCENE: Welcome ===
Hello {name},
**
We have an important bonus update for you.

=== SCENE: Performance Summary ===
This quarter, your goal was {goal}.
**
Since you managed to achieve {actual_performance},
**
Your bonus will be {actual_bonus}.
**
Congratulations!

=== SCENE: Bonus Selection ===
*Choose Your Bonus Type*
Please choose how you would like to receive your bonus:
[[ BUTTON: Cash Payment ]] or [[ BUTTON: Options ]]

Please format the script according to these instructions and examples.
            `
          }
        ],
        max_tokens: 1500,
        n: 1,
        temperature: 0.7,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${OPENAI_API_KEY}`,
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
    throw error;
  }
}
