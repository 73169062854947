import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/useStore";
import styled from "styled-components";
import { getSnapshot } from "mobx-state-tree";
import { Input } from "antd";
const FormContainer = styled.div`
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 14px;
  margin-bottom: 10px;
  overflow-y: auto;
  display: flex;
  height: 100%;
  width: 100%;
  scrollbar-width: thin !important;
  scrollbar-color: #999 #ff7f5000 !important;
  align-content: flex-start;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #f5f7fa;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  color: #130f26;
  font-weight: 700;
  margin-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
`;

const DynamicDataForm: React.FC = observer(() => {
  const { scriptStore } = useStore();

  //   const debouncedUpdateVideoPlayer = useCallback(
  //     debounce(() => {
  //     }, 3000), // Adjust the debounce delay as needed (300ms in this example)
  //     [scriptStore]
  //   );

  const handleInputChange = (key: string, value: string) => {
    const dynamicData = scriptStore.dynamicData.get(key);
    if (dynamicData) {
      const dynamicDataField = getSnapshot(dynamicData);
      const updatedExamples = [...dynamicDataField.examples];
      updatedExamples[0] = value;
      scriptStore.updateDynamicDataExample(key, value);

      //   debouncedUpdateVideoPlayer();
    }
  };

  return (
    <FormContainer>
      {Array.from(scriptStore.dynamicData.keys()).map((key) => (
        <FormGroup key={key}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "10px 20px",
              width: "100%",
            }}
          >
            <Label title={key}>{key}</Label>
            <Input
              style={{
                marginBottom: "10px",
                backgroundColor: "#F5F7FA",
                marginTop: "5px",
                maxWidth: "220px",
              }}
              type="text"
              value={scriptStore.dynamicData.get(key)?.examples[0] || ""}
              onChange={(e) => handleInputChange(key, e.target.value)}
            />
          </div>
        </FormGroup>
      ))}
    </FormContainer>
  );
});

export default DynamicDataForm;
