import styled, { keyframes } from "styled-components";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/useStore";
import { SendIcon } from "../icons-and-animations/icons";
import ScriptToolbar from "./ScriptToolbar";
import { useEffect } from "react";

const ScriptContent = styled.div`
  flex-grow: 1;
  margin-bottom: 20px;
`;

const ToolbarIcons = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #130f26;
  padding: 10px 0;
`;

const ScriptDiv = styled.div`
  width: 100%;
  padding: 0 20px;
  border: 1px solid #e9edf3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #e9edf3;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  position: relative;
  color: #45464e;
`;

const TextAreaContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  height: 100%;
`;

const LongTextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  height: 100%;
  padding: 10px;
  border: none;
  resize: none;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #e9edf3;
  scrollbar-width: thin !important;
  scrollbar-color: #999 #ff7f5000 !important;

  &:focus {
    outline: none;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerWrapper = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: ${spin} 1s ease-in-out infinite;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 8px 12px;
  display: block;
// padding: 5px 12px;
  padding-right: 40px;
  border-radius: 8px;
  resize: none;
  overflow-y: auto;
  min-height: 46px;
  height: 46px;
  max-height: 180px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  line-
  color: #333;
  border: 1px solid #130F26;
  background-color: white;

  scrollbar-width: thin !important;
  scrollbar-color: #999 #ff7f5000 !important;

  &:focus {
    outline: none;  
    border: 1px solid #FF154D;  
    box-shadow: 0 0 25px rgba(255, 21, 77, 0.4); 
  }

  &::placeholder {
    color: #999;
  }
`;

const SendButton = styled.button`
  position: absolute;
  bottom: 5px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  height: 34px;
  width: 34px;
  padding: 0;
  stroke: #130f26;

  &:disabled {
    stroke: #c7c7c7 !important;
  }
  &:disabled:hover {
    stroke: #c7c7c7 !important;
    background: none;
    cursor: default;
  }

  &:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
  }
`;

const DescribeUseCase = styled.p`
  margin-top: 30px;
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: 700;
  color: #45464e;
`;

const ScriptHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #45464e;
  margin-bottom: 8px;
`;

const INPUT_DEFALUT_HEIGHT = 46;

export const ScriptSection = observer(() => {
  const { scriptStore } = useStore();

  useEffect(() => {
    // Set focus to the textarea when the component is mounted
    const textarea = document.getElementById("input-script");
    if (textarea) {
      textarea.focus();
    }

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "Enter") {
        convertToScript();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleTextAreaHight = (e: any) => {
    const target = e.target as HTMLTextAreaElement;
    if (target.scrollHeight > INPUT_DEFALUT_HEIGHT) {
      target.style.height = "auto";
    }
    const hight =
      target.value === "" || target.scrollHeight < INPUT_DEFALUT_HEIGHT
        ? INPUT_DEFALUT_HEIGHT
        : target.scrollHeight + 8;
    target.style.height = `${hight}px`;
  };

  const convertToScript = async () => {
    await scriptStore.convertToScript();
    await scriptStore.convertToMP5();
  };

  const handleInputChange = (e: any) => {
    scriptStore.setInputText(e.target.value);
  };

  const updateScript = (e: any) => {
    scriptStore.setScript(e.target.value);
    scriptStore.convertToMP5();
  };

  return (
    <Container>
      <ScriptHeader>
        <span style={{ fontSize: "16px", fontWeight: "700" }}>Script</span>
      </ScriptHeader>

      <ScriptDiv>
        <ToolbarIcons>
          <ScriptToolbar />
        </ToolbarIcons>
        <ScriptContent>
          {scriptStore.isLoading && (
            <Overlay>
              <SpinnerWrapper />
            </Overlay>
          )}
          <TextAreaContainer>
            <LongTextArea
              disabled={scriptStore.isLoading}
              id="formatted-script"
              value={scriptStore.script}
              onChange={(e) => updateScript(e)}
              placeholder="Formatted script will appear here..."
            />
          </TextAreaContainer>
        </ScriptContent>
      </ScriptDiv>

      <DescribeUseCase>
        Describe the use-case you'd like for this MP5
      </DescribeUseCase>
      <div style={{ position: "relative" }}>
        <StyledTextarea
          id="input-script"
          value={scriptStore.inputText}
          onChange={(e) => handleInputChange(e)}
          onInput={(e) => handleTextAreaHight(e)}
        />
        <SendButton
          disabled={!scriptStore.canConvertToScript || scriptStore.isLoading}
          onClick={() => convertToScript()}
        >
          <SendIcon />
        </SendButton>
      </div>
      <div>
        <p style={{ fontSize: "11px", color: "#45464E" }}>
          Example: Personalized video for new employees at blings, encouraging
          them to fill up a form.
        </p>
      </div>
    </Container>
  );
});
