// src/utils/scriptToBlings.ts

export interface DynamicDataField {
  type: string;
  examples: string[];
  format: string;
}

export interface ContentItem {
  type: "title" | "text" | "image" | "button";
  value: string;
  uid: number;
}

export interface Screen {
  content: ContentItem[];
}

export interface Connector {
  layerName: string;
  layerUid: number;
  type: string;
  value?: string;
  dataKey?: string;
  event?: "onclick";
}

export interface Scene {
  name: string;
  connectors: Connector[];
  screens: Screen[];
}

export interface BlingVideo {
  dynamicData: Record<string, DynamicDataField>;
  scenes: Scene[];
}

export function convertToBlings(formattedScript: string): BlingVideo {
  const scenes: Scene[] = [];
  const dynamicData: Record<string, DynamicDataField> = {};

  const lines = formattedScript.split("\n");

  const imageRegex = /\[\[\s*IMAGE:\s*([^\]]+)\s*\]\]/;
  const buttonRegex = /\[\[\s*BUTTON:\s*([^\]]+?)(?:\s*=>\s*([^\]]+))?\s*\]\]/;

  const processDynamicData = (
    text: string,
    currentScene: Scene,
    index: number
  ) => {
    const dynamicDataMatches = text.match(/\{([^}]+)\}/g);
    if (dynamicDataMatches) {
      dynamicDataMatches.forEach((match) => {
        const key = match.slice(1, -1);
        if (!dynamicData[key]) {
          dynamicData[key] = {
            type: "string",
            examples: [key],
            format: "text",
          };
        }
        if (text.trim() === `{${key}}`) {
          currentScene.connectors.push({
            layerName: `{${key}}`,
            layerUid: index,
            type: "text",
            dataKey: key,
          });
        } else {
          currentScene.connectors.push({
            layerName: text.trim(),
            layerUid: index,
            type: "text",
            value: text.replace(`{${key}}`, `\${${key}}`),
          });
        }
      });
    }
  };

  const processLine = (
    line: string,
    currentScene: Scene,
    currentScreen: Screen,
    index: number
  ) => {
    if (line.startsWith("*") && line.endsWith("*")) {
      const title = line.slice(1, -1).trim();
      currentScreen.content.push({ type: "title", value: title, uid: index });
      processDynamicData(title, currentScene, index);
    } else {
      const parts = line.split(
        /(\[\[\s*BUTTON:\s*[^\]]+\s*\]\]|\[\[\s*IMAGE:\s*[^\]]+\s*\]\])/
      );
      parts.forEach((part) => {
        const buttonMatch = part.match(buttonRegex);
        if (buttonMatch) {
          const buttonText = buttonMatch[1].trim();
          const buttonValue = buttonMatch[2]?.trim() || "blings.io";
          currentScreen.content.push({
            type: "button",
            value: buttonText,
            uid: index,
          });
          currentScene.connectors.push({
            layerName: "Button " + buttonText,
            layerUid: index,
            type: "interactiveOpen",
            value: buttonValue,
            event: "onclick",
          });
        } else if (imageRegex.test(part)) {
          const imageName = part.match(imageRegex)![1].trim();
          currentScreen.content.push({
            type: "image",
            value: imageName,
            uid: index,
          });
        } else if (part.trim()) {
          currentScreen.content.push({
            type: "text",
            value: part.trim(),
            uid: index,
          });
          processDynamicData(part.trim(), currentScene, index);
        }
      });
    }
  };

  for (const [index, line] of lines.entries()) {
    let currentScene: Scene | null = null;
    if (line.trim() === "") continue;
    if (line.startsWith("=== SCENE:")) {
      const sceneName = line
        .replace("=== SCENE:", "")
        .replace("===", "")
        .trim();
      currentScene = { name: sceneName, connectors: [], screens: [] };

      scenes.push(currentScene);
    } else {
      let currentScene = scenes[scenes.length - 1];
      if (!currentScene) {
        currentScene = { name: "Default Scene", connectors: [], screens: [] };
        scenes.push(currentScene);
      }
      let currentScreen = currentScene.screens[currentScene.screens.length - 1];
      if (!currentScreen) {
        currentScreen = { content: [] };
        currentScene.screens.push(currentScreen);
      }
      if (line === "**") {
        if (
          currentScene &&
          (currentScene.screens.length === 0 ||
            currentScene.screens[currentScene.screens.length - 1].content
              .length > 0)
        ) {
          currentScene.screens.push({ content: [] });
        }
      } else if (line.trim() !== "") {
        if (currentScene.screens.length === 0) {
          currentScene.screens.push({ content: [] });
        }

        const currentScreen =
          currentScene.screens[currentScene.screens.length - 1];
        processLine(line, currentScene, currentScreen, index);
      }
    }
  }

  return { dynamicData, scenes };
}
