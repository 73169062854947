import { flow, getRoot, types } from "mobx-state-tree";
import { Project, ProjectVersion } from "../API";
import { generateClient, GraphQLQuery } from "aws-amplify/api";
import {
  createVersion,
  getLatestProjectVersion,
  getPublishedProject,
  getPublishedProjectBasicInfo,
} from "../utils/projectTools";
import { IRootStore } from "./RootStore";

export type ProjectBasicInfo = {
  title: string;
  id: string;
  updatedAt: string;
  createdAt: string;
  projectAccountId: string;
  textToVideoScript: string;
  publishedAt: string;
};
export interface ListProjectsBasicInfoQuery {
  listProjects?: {
    items: Array<ProjectBasicInfo> | null;
  } | null;
}
const client = generateClient();
export const PlatformModel = types
  .model({
    selectedProjectId: types.maybeNull(types.string),
    selectedProjectBasicInfo: types.maybeNull(types.frozen<ProjectBasicInfo>()),
    allProjects: types.optional(
      types.array(types.frozen<ProjectBasicInfo>()),
      []
    ),
    // projectWorkspaceVersion: types.maybeNull(types.frozen<ProjectVersion>()),
    isLoadingProjectList: types.optional(types.boolean, false),
  })
  .views((self) => ({}))
  .actions((self) => ({
    setLoadingProjectList(isLoading: boolean) {
      self.isLoadingProjectList = isLoading;
    },
  }))
  .actions((self) => ({
    loadProjects: flow(function* () {
      self.setLoadingProjectList(true);
      const listProjectBasicInfo = `query ListProjects(
          $filter: ModelProjectFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              title
              updatedAt
              createdAt
              textToVideoScript
              projectAccountId
              publishedAt
            }
            nextToken
          }
        }`;

      let nextToken = null;
      const projects: ProjectBasicInfo[] = [];
      do {
        const projectsData = (yield client.graphql<
          GraphQLQuery<ListProjectsBasicInfoQuery>
        >({
          query: listProjectBasicInfo,
          variables: { nextToken },
        })) as { data: any };
        nextToken = projectsData?.data?.listProjects?.nextToken;
        if (projectsData?.data?.listProjects?.items) {
          projects.push(...projectsData.data.listProjects.items);
        }
      } while (nextToken);
      // self.initialProjectLoadSuccess = true;
      self.allProjects.replace(projects.filter((p) => !!p.id));
      self.setLoadingProjectList(false);
    }),
    selectProject: flow(function* (pid: string) {
      self.selectedProjectBasicInfo = null as ProjectBasicInfo | null;
      self.selectedProjectId = pid || null;
      let latestProjectVersion = (yield getLatestProjectVersion(
        pid
      )) as ProjectVersion;
      if (!latestProjectVersion) {
        // if there is no workspace version, create one out of the published project
        const publishedProject = (yield getPublishedProject(pid)) as Project;
        latestProjectVersion = (yield createVersion(
          publishedProject
        )) as ProjectVersion;
      }
      self.selectedProjectBasicInfo = yield getPublishedProjectBasicInfo(pid);
      const { projectStore, scriptStore } = getRoot<IRootStore>(self);
      scriptStore.setFormattedScript(
        latestProjectVersion.textToVideoScript || ""
      );
      scriptStore.setScript(latestProjectVersion.textToVideoScript || "");
      scriptStore.setFormattedScript(
        latestProjectVersion.textToVideoScript || ""
      );
      scriptStore.setInputChanged(false);
      scriptStore.setScriptChanged(true);
      scriptStore.convertToMP5();
      projectStore.setProject(
        latestProjectVersion,
        self.selectedProjectBasicInfo?.title || ""
      );
    }),
  }));
