import { types, flow, getSnapshot, Instance } from "mobx-state-tree";
import { callOpenAI } from "../services/openai";
import { convertToBlings } from "../utils/scriptToBlings";
import { convertToMP5 } from "../utils/convertToMP5";
import { Connector } from "../utils/scriptToBlings";
import { Animation } from "@blings/blings-player";
import {
  identifyScriptComponent,
  ScriptComponent,
} from "../utils/scriptComponents";
import { applyFormattingRules } from "../utils/formattingRules";

export const DynamicDataField = types.model({
  type: types.string,
  examples: types.array(types.string),
  format: types.string,
  example: types.maybe(types.string),
});

export const ScriptStore = types
  .model("ScriptStore", {
    inputText: types.optional(types.string, ""),
    script: types.optional(types.string, ""),
    formattedScript: types.optional(types.string, ""),
    isLoading: types.optional(types.boolean, false),
    inputChanged: types.optional(types.boolean, false),
    scriptChanged: types.optional(types.boolean, false),
    mp5Data: types.optional(types.array(types.frozen<Animation>()), []),
    connectors: types.optional(types.array(types.frozen<Connector[]>()), []),
    dynamicData: types.map(DynamicDataField),
    currentFrame: types.optional(types.number, 0),
    totalFrame: types.optional(types.number, 0),
    // data: types.optional(types.frozen(), {})
    // dynamicData: types.optional(types.map(DynamicDataField), {}),
    // dynamicData: types.map(types.model({
    //   type: types.string,
    //   examples: types.array(types.string),
    //   format: types.string
    // }))
  })
  .views((self) => ({
    get canConvertToScript() {
      return self.inputText.trim().length > 0 && self.inputChanged;
    },
    get canConvertToBlings() {
      return self.formattedScript.trim().length > 0 && self.scriptChanged;
    },
    get canConvertToMP5() {
      return self.formattedScript.trim().length > 0 && self.scriptChanged;
    },
  }))
  // Getters and Setters
  .actions((self) => ({
    setCurrentFrame(currentFrame: number, totalFrame: number) {
      self.currentFrame = currentFrame;
      self.totalFrame = totalFrame;
    },
    setInputText(text: string) {
      self.inputText = text;
      self.inputChanged = true;
    },
    setScript(script: string) {
      self.script = script;
    },
    setFormattedScript(script: string) {
      self.formattedScript = script;
      self.scriptChanged = true;
    },
    setInputChanged(value: boolean) {
      self.inputChanged = value;
    },
    setScriptChanged(value: boolean) {
      self.scriptChanged = value;
    },
    setIsLoading(value: boolean) {
      self.isLoading = value;
    },
  }))
  // CRUD
  .actions((self) => ({}))
  // Complex actions
  .actions((self) => ({
    /**
     * Apply formatting rules to the script
     * @param script The script to format
     */
    formatScript(script: string): string {
      const lines = script.split("\n");
      let formattedScript = "";
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
        let trimmedLine = line.trim();
        const lineType = identifyScriptComponent(trimmedLine);
        switch (lineType) {
          case ScriptComponent.SCENE:
            break;
          case ScriptComponent.SCREEN:
            line = applyFormattingRules.screen(trimmedLine);
            break;
          case ScriptComponent.BUTTON:
            break;
          case ScriptComponent.IMAGE:
            break;
          case ScriptComponent.TITLE:
            line = applyFormattingRules.title(trimmedLine);
            break;
          default:
            break;
        }
        formattedScript += line + "\n";
      }
      return formattedScript;
    },
    async convertToScript() {
      self.isLoading = true;
      try {
        const result: string = await callOpenAI(self.inputText);
        const formattedScript: string = this.formatScript(result);
        self.setScript(result);
        self.setFormattedScript(formattedScript);
        self.setInputChanged(false);
        self.setScriptChanged(true); // Set to true after receiving API response
      } catch (error) {
        console.error("Error converting to script:", error);
      }
      self.setIsLoading(false);
    },
    convertToBlings() {
      const blingJSON = convertToBlings(self.formattedScript);
      const currentKeys = Object.keys(self.dynamicData.toJSON());
      const formattedKeys = Object.keys(blingJSON.dynamicData);

      // Loop through currentKeys and update the corresponding blingJSON.dynamicData with current examples values
      currentKeys.forEach((key) => {
        if (formattedKeys.includes(key)) {
          blingJSON.dynamicData[key]["examples"][0] =
            self.dynamicData.get(key)?.examples[0] || "";
        }
      });
      self.dynamicData.replace(blingJSON.dynamicData); // Store the dynamic data

      // self.scriptChanged = false;
      return blingJSON;
    },
    convertToMP5() {
      const formatedScript = this.formatScript(self.script);
      self.setFormattedScript(formatedScript);
      const blingJSON = this.convertToBlings();
      const { animationData, connectors } = convertToMP5(blingJSON);
      self.connectors.replace(connectors); // Store the connectors
      self.mp5Data.replace(animationData); // Store the MP5 data
      return { animationData, connectors };
    },
    updateDynamicDataField(key: string, field: typeof DynamicDataField.Type) {
      self.dynamicData.set(key, field);
    },
    updateDynamicDataExample(key: string, example: string) {
      const field = self.dynamicData.get(key);
      if (field) {
        const updatedExamples = [...field.examples];
        updatedExamples[0] = example;
        const updatedField = DynamicDataField.create({
          ...getSnapshot(field),
          examples: updatedExamples,
        });
        self.dynamicData.set(key, updatedField);
      }
    },
  }));
export type ScriptStoreType = typeof ScriptStore.Type;
export type IScriptStore = Instance<typeof ScriptStore>;
