import { types, Instance, flow } from "mobx-state-tree";
import { fetchAuthSession } from "aws-amplify/auth";

export const AccountStore = types
    .model("AccountStore", {
        accounts: types.maybe(types.frozen()),

    })
    .actions((self) => ({
        getAccounts: flow(function* () {
            try {
                const fullAuth = yield fetchAuthSession();
                const session = fullAuth.tokens;
                if (!session) throw new Error("No session");
                if (!session.idToken) throw new Error("No idToken");
                const res = [session.idToken.payload["cognito:groups"]];
                self.accounts = res[0] || [];
            }
            catch (error) {
                console.error("Error fetching user accounts:", error);
            }
        }
        )
    }))

export type AccountStoreType = typeof AccountStore.Type;
export type IAccountStore = Instance<typeof AccountStore>;
