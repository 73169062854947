import { LayerType } from "@blings/blings-player";
import { ShapeLayer } from "@blings/blings-player/lib/src/libs/l/types/layers";

export const createBackgroundLayer = (
  totalLength: number,
  width: number,
  height: number
): ShapeLayer => ({
  ddd: 0,
  ind: 1,
  ty: LayerType.shape,
  nm: "Shape Layer 1",
  uid: 4351,
  sr: 1,
  ks: {
    o: {
      a: 0,
      k: 100,
      ix: 11,
    },
    r: {
      a: 0,
      k: 0,
      ix: 10,
    },
    p: {
      a: 0,
      k: [width / 2, height / 2, 0],
      ix: 2,
      l: 2,
    },
    a: {
      a: 0,
      k: [0, 0, 0],
      ix: 1,
      l: 2,
    },
    s: {
      a: 0,
      k: [100, 100, 100],
      ix: 6,
      l: 2,
    },
  },
  ao: 0,
  shapes: [
    //@ts-ignore
    {
      ty: "gr",
      it: [
        {
          ty: "rc",
          d: 1,
          s: {
            a: 0,
            k: [width, height],
            ix: 2,
          },
          p: {
            a: 0,
            k: [0, 0],
            ix: 3,
          },
          r: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: "Rectangle Path 1",
          mn: "ADBE Vector Shape - Rect",
          hd: false,
          // _render: true,
        },
        {
          //@ts-ignore
          ty: "gf",
          o: {
            a: 0,
            k: 100,
            ix: 10,
          },
          r: 1,
          bm: 0,
          g: {
            p: 3,
            k: {
              a: 0,
              k: [
                0,
                1,
                1,
                1,
                0.5,
                1,
                1,
                1,
                1,
                1,
                1,
                1
              ],
              ix: 9,
            },
          },
          s: {
            a: 0,
            k: [-389, 0],
            ix: 5,
          },
          e: {
            a: 0,
            k: [width, 0],
            ix: 6,
          },
          t: 1,
          nm: "Gradient Fill 1",
          mn: "ADBE Vector Graphic - G-Fill",
          hd: false,
          _render: true,
        },
        {
          ty: "st",
          c: {
            a: 0,
            k: [1, 1, 1, 1],
            ix: 3,
          },
          o: {
            a: 0,
            k: 100,
            ix: 4,
          },
          w: {
            a: 0,
            k: 0,
            ix: 5,
          },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: "Stroke 1",
          mn: "ADBE Vector Graphic - Stroke",
          hd: true,
          // _render: true,
        },
        {
          ty: "tr",
          p: {
            a: 0,
            k: [0, 0],
            ix: 2,
          },
          a: {
            a: 0,
            k: [0, 0],
            ix: 1,
          },
          s: {
            a: 0,
            k: [100, 100],
            ix: 3,
          },
          r: {
            a: 0,
            k: 0,
            ix: 6,
          },
          o: {
            a: 0,
            k: 100,
            ix: 7,
          },
          sk: {
            a: 0,
            k: 0,
            ix: 4,
          },
          sa: {
            a: 0,
            k: 0,
            ix: 5,
          },
          nm: "Transform",
          // _render: true,
        },
      ],
      nm: "Rectangle 1",
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: "ADBE Vector Group",
      hd: false,
      _render: true,
    },
  ],
  ip: 0,
  op: totalLength,
  st: 0,
  cp: true,
  bm: 0,
  completed: true,
});
