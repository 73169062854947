import styled from "styled-components";
import { useStore } from "../hooks/useStore";
import { Tooltip } from "antd";
import {
  AddFileIcon,
  ImageIcon,
  TicketIcon,
  EditIcon,
  ControllerIcon,
  TitleIcon,
} from "../icons-and-animations/icons";

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5px;
  gap: 10px;
`;

const ToolbarButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
`;

const ScriptToolbar = () => {
  const { scriptStore } = useStore();

  const addFormatting = (format: string) => {
    const textarea = document.getElementById(
      "formatted-script"
    ) as HTMLTextAreaElement;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = textarea.value;
    let insertion = "";

    switch (format) {
      case "new-screen":
        insertion = "\n**\n";
        break;
      case "title":
        insertion = "\n*Title Text*\n";
        break;
      case "scene":
        insertion = "\n=== SCENE: New Scene ===\n";
        break;
      case "button":
        insertion = "\n[[ BUTTON: Button Text => example.com]]\n";
        break;
      case "image":
        insertion = "\n[[ IMAGE: Description ]]\n";
        break;
      case "dynamic":
        insertion = "{variable}";
        break;
    }

    const newText = text.substring(0, start) + insertion + text.substring(end);
    scriptStore.setScript(newText);
    scriptStore.convertToMP5();

    // Set cursor position after insertion
    setTimeout(() => {
      textarea.setSelectionRange(
        start + insertion.length,
        start + insertion.length
      );
      textarea.focus();
    }, 0);
  };
  const toolbarList = [
    { title: "New Scene", icon: <AddFileIcon />, action: "scene" },
    { title: "New Screen", icon: <TicketIcon />, action: "new-screen" },
    { title: "Add Button", icon: <ControllerIcon />, action: "button" },
    { title: "Add Image", icon: <ImageIcon />, action: "image" },
    { title: "Add Title", icon: <TitleIcon />, action: "title" },
    { title: "Add Dynamic Value", icon: <EditIcon />, action: "dynamic" },
  ];

  return (
    <ToolbarContainer>
      {toolbarList.map((item, index) => (
        <Tooltip placement="top" title={item.title} key={index}>
          <ToolbarButton onClick={() => addFormatting(item.action)}>
            {item.icon}
          </ToolbarButton>
        </Tooltip>
      ))}
    </ToolbarContainer>
  );
};

export default ScriptToolbar;
