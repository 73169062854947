import styled from "styled-components";
import { useStore } from "./hooks/useStore";
import ScriptConverter from "./components/ScriptConverter";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { rootStore } from "./models/RootStore";
import { useEffect } from "react";
const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f5f7fa;
  width: 100%;
`;

function App() {
  const { accountStore } = useStore();

  useEffect(() => {
    const initializeAccount = async () => {
      if (!accountStore.accounts) {
        await accountStore.getAccounts();
      }
    };
    rootStore.platformStore.loadProjects();
    initializeAccount();
  }, []);
  return (
    <AppContainer>
      <ScriptConverter />
    </AppContainer>
  );
}

export default withAuthenticator(App);
