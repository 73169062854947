/**
 * Validates a new project name.
 * @param name The project name to validate.
 * @returns An object with a boolean 'isValid' and a string 'error' message if invalid.
 */
export function validateNewProjectName(name: string): { isValid: boolean; error?: string } {
    // Trim the name to remove leading and trailing whitespace
    const trimmedName = name.trim();

    // Check if the name is empty
    if (trimmedName.length === 0) {
        return { isValid: false, error: "Project name cannot be empty." };
    }

    // Check if the name is too long
    if (trimmedName.length > 200) {
        return { isValid: false, error: "Project name cannot exceed 200 characters." };
    }

    // Regular expression to check for potential code execution patterns
    const codeExecutionPattern = /[;`"'{}()\[\]$]/;
    if (codeExecutionPattern.test(trimmedName)) {
        return { isValid: false, error: "Project name contains invalid characters." };
    }

    // Additional check for JavaScript-like syntax
    const jsKeywords = ['function', 'eval', 'setTimeout', 'setInterval', 'new Function'];
    if (jsKeywords.some(keyword => trimmedName.toLowerCase().includes(keyword))) {
        return { isValid: false, error: "Project name contains potentially unsafe terms." };
    }

    // If all checks pass, the name is valid
    return { isValid: true };
}