import { Modal } from "antd";
import { useState } from "react";
import { Select, Input, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useStore } from "../hooks/useStore";
import { validateNewProjectName } from "../utils/helpers";
interface CreateProjectProps {
  isOpen: boolean;
  onCancel: () => void;
  onOk: (input: string, a1: string) => void;
}
const createBtnColor =
  "linear-gradient(258.38deg, #FF154D 1.82%, #FF154D 35.15%, #FF7AAA 98.2%)";

const CreateProjectModal = ({ isOpen, onOk, onCancel }: CreateProjectProps) => {
  const [projectName, setProjectName] = useState("");
  const [accountName, setAccountName] = useState("");
  const { accountStore } = useStore();
  const [loading, setLoading] = useState(false);

  const handleProjectNameChange = (event: any) => {
    setProjectName(event.target.value);
  };
  const validateProjectName = () => {
    return validateNewProjectName(projectName).isValid;
  };
  const validateForm = () => {
    return validateProjectName() && accountName !== "";
  };

  const handleCreate = async () => {
    setLoading(true);
    await onOk(projectName, accountName);
    setLoading(false);
  };

  return (
    <Modal
      title="Create Project"
      open={isOpen}
      destroyOnClose
      onCancel={() => onCancel()}
      closable={!loading}
      width={400}
      okText="Create"
      okButtonProps={{
        disabled: !validateForm(),
        style: {
          background: validateForm() ? createBtnColor : "#C7C7C7",
          color: "white",
        },
      }}
      footer={[
        <Button disabled={loading} key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          loading={loading}
          onClick={handleCreate}
          disabled={!validateForm()}
          style={{
            background: validateForm() ? createBtnColor : "#C7C7C7",
            color: "white",
          }}
        >
          Create
        </Button>,
      ]}
    >
      <Spin
        indicator={<LoadingOutlined spin />}
        spinning={loading}
        tip="Creating Project"
        size="large"
        style={{ color: "#ff164d" }}
        fullscreen
      ></Spin>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "300px",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "300px",
          }}
        >
          <label>Project Name</label>
          <Input
            style={{ minWidth: "200px" }}
            value={projectName}
            onChange={(event) => handleProjectNameChange(event)}
            placeholder="Project Name"
            status={validateProjectName() ? "" : "error"}
          />
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "300px",
          }}
        >
          <label>Account</label>
          <Select
            placeholder="Select Account"
            style={{ minWidth: "200px" }}
            onChange={(account) => setAccountName(account)}
          >
            {accountStore.accounts?.map((option: string) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <br />
    </Modal>
  );
};

export default CreateProjectModal;
