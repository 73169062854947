export const applyFormattingRules = {
    title: (line: string): string => {
        return removeLeadingAndTrailingSpaces(line);
    },
    screen: (line: string): string => {
        return removeLeadingAndTrailingSpaces(line);
    },
}

/**
 * Remove empty spaces from the start and end of a title line
 * @param line Line to remove empty spaces from
 */
function removeLeadingAndTrailingSpaces(line: string): string {
    return line.trim();
}