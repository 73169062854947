import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/useStore";
import { ConnectToProject } from "./ConnectToProject";

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #ffffff;
  height: 50px;
  border-radius: 5px;
`;

const Logo = styled.img`
  height: 30px;
  margin: 0 10px;
`;

const CreateButton = styled.button`
  padding: 0px 8px;
  gap: 8px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 7px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 700;
  width: 180px;
  height: 32px;
  color: white;

  background: linear-gradient(
    258.38deg,
    #ff154d 1.82%,
    #ff154d 35.15%,
    #ff7aaa 98.2%
  );

  &:disabled {
    background: #cccccc;
  }
`;

interface HeadertProps {
  showModal: () => void;
}

const Header = observer(({ showModal }: HeadertProps) => {
  const { scriptStore, projectStore } = useStore();
  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Logo style={{ height: "25px" }} src="logo.svg" alt="blings.io" />
        <p style={{ fontSize: "14px", fontWeight: "400", color: "#161130" }}>
          MP5 Generator
        </p>
        <ConnectToProject />
      </div>
      {!projectStore.id ? (
        <CreateButton
          onClick={() => showModal()}
          disabled={!scriptStore.canConvertToBlings}
        >
          Create Project
        </CreateButton>
      ) : (
        <CreateButton
          onClick={() => {
            projectStore.updateProject();
          }}
          disabled={!scriptStore.canConvertToBlings}
        >
          Update Project
        </CreateButton>
      )}
    </Container>
  );
});
export default Header;
