import { uploadData } from "aws-amplify/storage";
import { CDN, getEnv, TEMPLATE_IMAGE_URL } from "./getEnv";
import { fetchAuthSession } from "aws-amplify/auth";

export async function downloadTemplateImage(fileName: string = "") {
  try {
    const response = await fetch(TEMPLATE_IMAGE_URL + fileName);
    if (!response.ok) {
      throw new Error(`Failed to download image: ${response.statusText}`);
    }
    const arrayBuffer = await response.arrayBuffer(); // Fetch image as ArrayBuffe
    if (!arrayBuffer) {
      throw new Error("Failed to download image");
    }

    return arrayBuffer;
  } catch (error) {
    if (getEnv() === "dev") {
      console.error("Error downloading or uploading the image:", error);
    }
  }
  return null;
}

const prefix = "https://cors.blings.io/"; // to avoid CORS issues

const API_URLS = {
  image: {
    dev: "https://tee1st7f3d.execute-api.eu-west-1.amazonaws.com/dev/v1/private/convert/image",
    staging:
      "https://tee1st7f3d.execute-api.eu-west-1.amazonaws.com/dev/v1/private/convert/image",
    master:
      "https://xtt82dfuj8.execute-api.eu-west-1.amazonaws.com/master/v1/private/convert/image",
  },
};

const getAuthorizationHeaderValue = async () => {
  try {
    const authSession = await fetchAuthSession();
    const accessToken = authSession.tokens?.accessToken.toString() as string;
    return accessToken;
  } catch (error) {
    console.error("Error retrieving authorization header value:", error);
  }
};

export const checkIfFileExists = async (key: string) => {
  try {
    const url = `${CDN}/${key}`;
    const response = await fetch(url, {
      method: "HEAD",
      referrer: "no-referrer",
    });

    switch (response.status) {
      case 200:
        return true;
      case 404:
      case 403:
        return false;
      default:
        throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (e) {
    if (getEnv() === "dev") {
      console.log("File likely does not exist:", e);
    }
    return false;
  }
};

export async function convertImageToWebP(params: {
  file: string;
  key: string;
}) {
  const parsedFileName = params.key.replace(/\.[^\.]+$/, ".webp");
  const fileExists = await checkIfFileExists(parsedFileName);
  if (fileExists) {
    return { key: parsedFileName };
  }
  const endpoint = prefix + API_URLS.image[getEnv()];
  const accessToken = await getAuthorizationHeaderValue();
  if (!accessToken) throw new Error("Invalid id or authorization");

  const data = {
    key: params.key,
  };

  const response = await fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: accessToken,
    },
  });
  const res = await response.json();
  return { key: res.publicKey.webp };
}

export const uploadImageToS3 = async (key: string, fileName: string = "") => {
  const imageFile = await downloadTemplateImage(fileName);
  if (imageFile) {
    try {
      const uploadOptions = {
        path: `public/${key}`,
        data: imageFile,
        options: {
          accessLevel: "guest",
          contentType: "application/octet-stream",
        },
      };
      await uploadData(uploadOptions).result;
    } catch (e) {
      if (getEnv() === "dev") {
        console.error("Failed to upload image to S3", e);
      }
    }
  }

  try {
    return await convertImageToWebP({ file: key, key });
  } catch (e) {
    if (getEnv() === "dev") {
      console.log("failed to convert. Using fallback", e);
    }
  }
  return { key };
};
